import styled from '@emotion/styled'
import React from 'react'
import InViewMonitor from 'react-inview-monitor'

import useLocalisation from '../../helper/useLocalisation'
import dream1 from '../../images/2021-fathers-day/1.svg'
import dream2 from '../../images/2021-fathers-day/2.svg'
import dream3 from '../../images/2021-fathers-day/3.svg'
import dream4 from '../../images/2021-fathers-day/4.svg'
import handle from '../../images/category/handle.svg'

const FadeUp = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeInUp ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

// Details
const DetailsContainer = styled.div`
  padding: 64px 0;
  ${(props) => (props.color ? `background: ${props.color}` : '')}
`

const DetailSectionsContaniner = styled.div`
  height: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 64px;
  overflow: hidden;
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 763px) {
    flex-wrap: wrap;
    height: auto;
    padding: 0 16px;
  }
`

const DetailSectionImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
`
const DetailSectionImageContainer = styled.div`
  width: 70%;
  margin: 0 15%;
  @media (max-width: 763px) {
    width: 90%;
    margin: 0 auto;
  }
  @media (min-width: 762px) {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
  }
`
const DetailSectionHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.71px;
  text-align: center;
  color: #282828;
  padding: 16px 0;
  min-height: calc(3.25em * 2);
  @media (min-width: 762px) {
    min-height: calc(2.25em * 2);
  }
`

const DetailSectionContainer = styled.div`
  padding: 0 32px;
  width: 25%;
  @media (max-width: 763px) {
    padding: 0px;
    width: calc(50% - 32px);
  }
`

const ReversableDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 763px) {
    ${(props) =>
      props.reverse
        ? 'flex-direction: column-reverse;'
        : 'flex-direction: column;'}
  }
`

const DetailSection = ({ data }) => {
  return data.map((section, key) => {
    return (
      <DetailSectionContainer key={key}>
        <FadeUp delay={key}>
          <ReversableDiv reverse={key > 1}>
            <DetailSectionHeading> {section.title} </DetailSectionHeading>
            <DetailSectionImageContainer>
              <DetailSectionImage src={section.image} />
            </DetailSectionImageContainer>
          </ReversableDiv>
        </FadeUp>
      </DetailSectionContainer>
    )
  })
}

const DetailsSection = ({ bags }) => {
  const Q = useLocalisation()
  return (
    <DetailsContainer color={'rgb(246, 243, 239)'}>
      <DetailSectionsContaniner>
        <DetailSection
          data={[
            {
              image: dream1,
              title: (
                <span style={{ textTransform: 'uppercase' }}>
                  COMPLIMENTARY
                  <br />
                  {Q('Personalisation')}
                </span>
              ),
              copy: '',
            },
            {
              title: (
                <span style={{ textTransform: 'uppercase' }}>
                  LIFETIME WARRANTY
                  <br />
                  FOR LUGGAGE
                </span>
              ),
              image: dream2,
              copy: '',
            },
            {
              title: (
                <span style={{ textTransform: 'uppercase' }}>
                  FREE <br />
                  SHIPPING
                </span>
              ),
              image: dream3,
              copy: '',
            },
            {
              title: (
                <span style={{ textTransform: 'uppercase' }}>
                  100 DAY <br />
                  RETURNS
                </span>
              ),
              image: dream4,
              copy: '',
            },
          ]}
        />
      </DetailSectionsContaniner>
    </DetailsContainer>
  )
}

export default DetailsSection
